@import "styles/_shared-variables";

$page-spacing: 16px;
$header-height: 65px;
$logo-height: 80px;
$logo-padding: $spacing-base;
$table-button-height: 30px;
$button-height: 40px;
$input-line-height: 26px;
$input-height: 40px;

$field-spacing: $spacing-x-small;
$field-padding: $spacing-x-large;

@import "~@edgetier/components/dist/style-mixins";

form {
    $animation-duration: 100ms;
    display: block;
    .field > textarea,
    .field > input[type="text"],
    .field > input[type="number"],
    .field > input[type="date"],
    .field > input[type="email"],
    .react-select__control,
    .dynamic-form__net-promoter-score .dynamic-form__rating,
    .dynamic-form__radios,
    .dynamic-form__stars {
        border: 2px solid #d9d9d9 !important;
        border-radius: 6px;
        background-color: #fbfbfb;
    }
    .field > textarea:focus,
    .field > input[type="text"]:focus,
    .field > input[type="number"]:focus,
    .field > input[type="date"]:focus,
    .field > input[type="email"]:focus,
    .react-select__control--is-focused {
        border-color: $flat-asbestos !important;
        background-color: #fff !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) !important;
    }
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="date"],
    input[type="email"] {
        &:focus {
            outline: none;
        }
    }
    .field > textarea,
    .field > input[type="text"],
    .field > input[type="number"],
    .field > input[type="date"],
    .field > input[type="email"] {
        display: block;
        width: 100%;
        padding: $field-padding;
        transition: all 150ms ease-out;
        &::-ms-clear {
            display: none;
        }
    }
    fieldset {
        border: none;
        padding: 0;
    }
    textarea {
        resize: none;
        display: block;
        border: none;
        -ms-overflow-style: none;
    }
    .field > label {
        display: inline-block;
        font-weight: bold;
        font-size: $font-size-medium;
    }
    .field > label,
    .field__error {
        margin-bottom: $spacing-xxx-small;
    }
    .field {
        margin-bottom: $spacing-xx-large;
    }
    input[type="radio"] + label svg {
        transition: color 250ms ease-out;
    }
    button[type="submit"] {
        cursor: pointer;
        width: 100%;
        padding: $spacing-large;
        border: 3px solid $flat-belize-hole;
        border-radius: 12px; // Copy dynamic formbutton styles
        font-size: $font-size-base;
        transition: all $timing-ease 0.28s;
        color: $flat-belize-hole;
        background-color: transparent;

        svg {
            margin-right: $field-spacing;
        }

        &:focus,
        &:hover {
            background-color: $flat-peter-river;
            color: $flat-clouds;
        }

        @include from(tablet) {
            display: block;
            width: auto;
            min-width: 33%;
            margin: auto;
        }
    }
    .field:nth-child(-n + 10) {
        @include pop--in;
    }
    @for $index from 1 through 10 {
        .field:nth-child(#{$index}) {
            @include pop--in;
            animation-duration: $animation-duration;
            animation-delay: (($index - 1) * $animation-duration);
        }
    }

    @include radio();
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea {
    width: 100%;
}

.field__error {
    @include slide-left--in;
    color: tomato;
    font-size: $font-size-medium;
    font-weight: bold;
    svg {
        display: none;
    }
}
