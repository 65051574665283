@import "styles/_shared-variables";

.page.error-page {
    text-align: center;
    padding: 10px;

    > * + * {
        margin-top: $spacing-large;
    }

    .search-icon {
        color: $flat-asbestos;
    }

    .error-display {
        &__primary {
            font-size: $font-size-large;
        }
        &__line-1 {
            font-size: $font-size-base;
        }
    }
}
